import { IAsset } from 'interfaces/global';
import useGlobal from './useGlobal';
import useTicker from './useTicker';
import useUser from './useUser';

const useBalance = () => {
  const global = useGlobal();
  const ticker = useTicker();
  const user = useUser();

  const balance = {
    _id: '',
    name: 'Toman',
    nameFa: 'تومان',
    symbol: 'tmn',
    icon: './images/tmn.png',
    balance: user.balance.fiat.free,
    value: {
      usdt: user.balance.fiat.free / ticker.price('usdttmn', 1),
      tmn: user.balance.fiat.free,
    },
  };

  const coins = Object.values(global.coins).map((e) => {
    return {
      _id: e._id,
      name: e.name,
      nameFa: e.nameFa,
      symbol: e.symbol,
      icon: e.icon,
      balance: user.balance.asset[e.symbol]?.free || 0,
      value: {
        usdt: (user.balance.asset[e.symbol]?.free || 0) * ticker.price(e.symbol + 'usdt', 1),
        tmn: (user.balance.asset[e.symbol]?.free || 0) * ticker.price(e.symbol + 'tmn', 1),
      },
    };
  });

  coins.push(balance);

  return coins.reduce((a, v) => ({ ...a, [v.symbol]: v }), {}) as { [key: string]: IAsset };
};

export default useBalance;
