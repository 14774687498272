import SelectBox from 'components/Helper/SelectBox';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { ICard } from 'interfaces/global';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import bank from 'utils/bank';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import NewCard from '../NewCard';

const Fiat = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [card, setCard] = useState<ICard | null>(null);
  const user = useUser();
  const global = useGlobal();

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/payment`, true).post({
      amount: amount,
      card: card?._id,
      callback: config.data.pwa + '/wallet',
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      window.location.href = result.data.url;
    } else if (result.message === 403) {
      dispatch(errorAlert('لطفا VPN خود را خاموش کنید'));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب شماره کارت</h3>
        <section className='addCard' onClick={() => dispatch(fullDrawer(<NewCard />))}>
          افزودن کارت
        </section>
      </div>

      <SelectBox
        data={user.cards}
        search={false}
        value={
          card ? (
            <>
              <img src={bank.detect(card.number!)?.logo || './svg/cardDown.svg'} />
              <h3>{card.bank}</h3>
              <h2>{card.number!.match(new RegExp('.{1,4}', 'g'))?.join('-')}</h2>
            </>
          ) : (
            <>شماره کارت خود را انتخاب کنید</>
          )
        }
        template='card'
        setSelected={setCard}
      />
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>مقدار واریزی</h3>
      </div>
      <div className='mainInput'>
        <div>
          <NumericFormat
            inputMode='numeric'
            className='ltr'
            value={amount > 0 ? amount : ''}
            allowNegative={false}
            thousandSeparator={true}
            onValueChange={(e) => setAmount(Number(e.value))}
            placeholder='0.0'
          />
        </div>
      </div>
      <div className='defValues'>
        <span onClick={() => setAmount(500000)}>۵۰۰ هزار</span>
        <span onClick={() => setAmount(1000000)}>۱ میلیون</span>
        <span onClick={() => setAmount(3000000)}>۳ میلیون</span>
        <span onClick={() => setAmount(5000000)}>۵ میلیون</span>
      </div>
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
        تایید و پرداخت آنلاین
      </div>
      <div className='listCashCode'>
        <section>
          <div>
            <span className='colorBlue'>حداقل میزان واریز {autoFormatter(global.setting.fiat.deposit.min)} تومان می‌باشد</span>
          </div>
        </section>
      </div>
    </>
  );
};

export default Fiat;
