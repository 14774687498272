import React, { useState } from 'react';
import { CircularProgress } from "@mui/material";
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { errorAlert } from 'services/reducer/alert';
import config from 'config';
import gather from 'utils/gather';
import VerifyCodeEmailPhone from './VerifyCodeEmailPhone';
import Top from '../Top';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';

const AddEmail = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  // const [errorEmail, setErrorEmail] = useState(false);
  const [myLoading, setMyLoading] = useState(false);

    const openMenu = (content: JSX.Element) => {
      dispatch(hideDrawer());
      setTimeout(() => {
        dispatch(fullDrawer(content));
      }, 300);
    };


  const send = async () => {
    if (!email.endsWith(".com") || !email.concat("@")) {
      dispatch(errorAlert('لطفا ایمیل را وارد کنید'));
      // setErrorEmail(true);
      // setTimeout(() => { setErrorEmail(false) }, 1000);
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.data.api}/v1/user/email`, true).post({ email: email })

    if (result.code === 200) {
      openMenu(<VerifyCodeEmailPhone flag={false} value={email} />);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    setMyLoading(false);
  }

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={'افزودن ایمیل جدید'} />
        <div className='logo'></div>
        <div className='container inData addEmail'>
          <div className='mainInput'>
            <p>ایمیل جدید</p>
            <div>
              <span className='email2'></span>
              <input
                type="email"
                autoComplete='one-time-code'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          {myLoading
            ? <div className="loadingCircular mt_15"><CircularProgress size={24} style={{ color: "#fff" }} /></div>
            : <div className='largeButton' onClick={send}>  ثبت</div>}
        </div>
      </div>
    </div>
  )
}

export default AddEmail;