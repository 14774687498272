import Cards from 'components/Modules/Cards';
import ChangePass from 'components/Modules/ChangePass';
import Deposit from 'components/Modules/Deposit';
import Gift from 'components/Modules/Gift';
import History from 'components/Modules/History';
import Login from 'components/Modules/Login';
import NewTicket from 'components/Modules/NewTicket';
import PriceAlert from 'components/Modules/PriceAlert';
import Register from 'components/Modules/Register';
import Security from 'components/Modules/Security';
import Tickets from 'components/Modules/Tickets';
import Tutorial from 'components/Modules/Tutorial';
import Withdraw from 'components/Modules/Withdraw';
import useBalance from 'hooks/useBalance';
import useUser from 'hooks/useUser';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { autoFormatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';
import LevelList from './LevelList';
import Top from './Top';
import Profile from 'components/Modules/Profile';

const Menu = () => {
  const dispatch = useDispatch();
  const { profile } = useUser();
  const balance = useBalance();
  const [menu, setMenu] = useState({
    ticket: false,
    bank: false,
    setting: false,
    wallet: false,
  });

  const logout = () => {
    Cookies.remove('pwaAccessToken');
    Cookies.remove('pwaRefreshToken');
    window.location.href = './';
  };

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={'پروفایل کاربری'} />
        <div className='profileMenu'>
          {isUserLogin() ? (
            <>
              <div className='profileImage'>
                <img src='/svg/profile.svg' />
                <section>
                  <p>{(profile.name || '-') + ' ' + (profile.family || '-')}</p>
                  <p>سطح کاربر {profile.plane?.level?.index}</p>
                </section>
              </div>
              <div className='currentLevel'>
                سطح کنونی شما <b>{profile.plane?.level?.name || '-'}</b>
              </div>
              <div className='currentLevel currentBalance'>
                جمع کل دارایی <b>{autoFormatter(Object.values(balance).reduce((n, e) => n + e.value.tmn, 0))}</b>
              </div>
              <div className='menuList'>
                <div
                  onClick={() =>
                    setMenu((prevState) => {
                      return { ...prevState, ticket: !prevState.ticket };
                    })
                  }
                  className={`mn_${menu.ticket}`}
                >
                  تیکت ها
                </div>
                {menu.ticket && (
                  <>
                    <span onClick={() => openMenu(<NewTicket />)}>ثبت تیکت جدید</span>
                    <span onClick={() => openMenu(<Tickets />)}>تاریخچه</span>
                  </>
                )}
                <div
                  onClick={() =>
                    setMenu((prevState) => {
                      return { ...prevState, bank: !prevState.bank };
                    })
                  }
                  className={`cardIcon mn_${menu.bank}`}
                >
                  حساب بانکی
                </div>
                {menu.bank && (
                  <>
                    <span onClick={() => openMenu(<Cards />)}>کارت های بانکی</span>
                  </>
                )}

                <div
                  onClick={() =>
                    setMenu((prevState) => {
                      return { ...prevState, wallet: !prevState.wallet };
                    })
                  }
                  className={`walletIcon mn_${menu.wallet}`}
                >
                  کیف پول
                </div>
                {menu.wallet && (
                  <>
                    <span onClick={() => openMenu(<History />)}>تاریخچه</span>
                    <span onClick={() => openMenu(<Deposit />)}>واریز</span>
                    <span onClick={() => openMenu(<Withdraw />)}>برداشت</span>
                  </>
                )}

                <div
                  onClick={() =>
                    setMenu((prevState) => {
                      return { ...prevState, setting: !prevState.setting };
                    })
                  }
                  className={`settingIcon mn_${menu.setting}`}
                >
                  تنظیمات
                </div>
                {menu.setting && (
                  <>
                    <span onClick={() => openMenu(<Profile />)}>پروفایل</span>
                    <span onClick={() => openMenu(<ChangePass />)}>تغییر رمز</span>
                    <span onClick={() => openMenu(<Security />)}>امنیت</span>
                  </>
                )}
                <div className='bellsIcon' onClick={() => openMenu(<PriceAlert />)}>
                  هشدار قیمت
                </div>
                <div className='giftIcon' onClick={() => openMenu(<Gift />)}>
                  کارت هدیه
                </div>
                <div className='outIcon' onClick={() => logout()}>
                  خروج
                </div>
                <div className='bookIcon' onClick={() => openMenu(<Tutorial />)}>
                  آموزش
                </div>
                <section className='social'>
                  <p>شبکه های اجتماعی ما را دنبال کنید</p>
                  <section>
                    <a href='#'></a>
                    <a href='#'></a>
                    <a href='#'></a>
                    <a href='#'></a>
                  </section>
                </section>
                <div className='menuTitle menuTitleLevel' onClick={() => dispatch(fullDrawer(<LevelList />))}>
                  ارتقا سطح حساب
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='logo'></div>
              <p className='rules rulesCenter'>
                جهت استفاده از خدمات سایت ثبت نام نمایید
                <br />
                یا وارد سایت شوید
              </p>
              <div className='menuReg'>
                <section onClick={() => openMenu(<Register />)}>ثبت نام</section>
                <section onClick={() => openMenu(<Login />)}>ورود</section>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
