import { CircularProgress, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import AddEmail from './AddEmail';
import VerifyCodeEmailPhone from './VerifyCodeEmailPhone';
import { useState } from 'react';
import { hideDialog } from 'services/reducer/dialog';
import gather from 'utils/gather';
import config from 'config';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';


const QustionAddOrEditEmail = ({ value }: { value: any }) => {
  const dispatch = useDispatch<any>();
  const [myLoading, setMyLoading] = useState(false);

    const openMenu = (content: JSX.Element) => {
      dispatch(hideDrawer());
      dispatch(hideDialog());

      setTimeout(() => {
        dispatch(fullDrawer(content));
      }, 300);
    };

  const sendCodeToExistEmial = async () => {
    setMyLoading(true);

    const result = await gather(`${config.data.api}/v1/user/email`, true).post({ "email": value })

    if (result.message === 200) {
      openMenu(<VerifyCodeEmailPhone flag={false} value={value} />)

    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>ایمیل</h4>
          </div>
          <section className="cancelOrder" onClick={() => dispatch(hideDialog())}></section>
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>
          کاربر گرامی شما می توانید با ایمیل موجود  {value}  ادامه دهید و
          یا ایمیل جدید ثبت نمایید.
        </p>
        <div className='btns'>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className='btOk' onClick={sendCodeToExistEmial}>ادامه</button>
          }
          <button onClick={() => !myLoading && openMenu(<AddEmail />)}>ثبت ایمیل جدید</button>
        </div>
      </DialogContent>
    </>
  )
}

export default QustionAddOrEditEmail;