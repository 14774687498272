import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import { useEffect, useState } from 'react';
import Code from './Code';
import Crypto from './Crypto';
import Fiat from './Fiat';
import moment from 'jalali-moment';
import { statusColor } from 'utils/color';
import { statusTranslate } from 'utils/translate';
import { autoFormatter } from 'utils/numeral';
import { centerDialog } from 'services/reducer/dialog';
import Detail from '../Detail';
import useGlobal from 'hooks/useGlobal';
import { useDispatch } from 'react-redux';
import config from 'config';
import gather from 'utils/gather';
import LoadingList from 'components/LoadingList';

const tabTitles = {
  toman: 'تومان',
  more: '۲۵+ میلیون تومان',
  crypto: 'ارز دیجیتال',
};

const Deposit = ({ symbol }: { symbol?: string }) => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(tabTitles.toman);
  const [listHistory, setListHistory] = useState([]);
  const [sliceNum, setSliceNum] = useState(3);
  const [loadingList, setLoadingList] = useState(true);
  const [from, _setFrom] = useState(
    Math.floor(
      moment()
        .subtract(360, 'd')
        .valueOf(),
    ),
  );
  const [to, _setTo] = useState(
    Math.floor(
      moment()
        .add(10, 'm')
        .valueOf(),
    ),
  );

  const getData = async (address: string) => {
    const result = await gather(`${config.data.api}/v1/${address}?fromDate=${from}&toDate=${to}`, true).get();
    if (result.code === 200) {
      console.log((tabTitles as any));
      return result.data.map((e: any) =>
        Object.assign(
          { tab: address === 'payment' ? e.side === 'deposit' ? tabTitles.toman : 'paymentDeposit' : tabTitles.crypto },
          e,
        ),
      );
    }
    return [];
  };

  const init = async () => {
    setLoadingList(true);

    setListHistory([]);

    const result = await Promise.all([getData('deposit'), getData('payment')]).then((results) => {
      return [].concat.apply([], [results[0], results[1]]);
    });

    setListHistory(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));

    setLoadingList(false);
  };

  useEffect(() => {
    init();
  }, []);


  useEffect(() => {
    if (symbol) {
      if (symbol !== 'tmn') {
        setTab(tabTitles.crypto);
      }
    }
  }, []);

  console.log(tab=="ارز دیجیتال"?"ارز دیجیتال":"تومان",listHistory);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'واریز'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.toman ? <Fiat /> : tab === tabTitles.more ? <Code /> : <Crypto symbol={symbol} />}
        </div>
        {tab === tabTitles.more && (
          <div className='container codeAlert'>
            <p>کاربر گرامی، به دستور پلیس فتا محترم ؛ بعد از استفاده از واریز شناسه‌دار، امکان برداشت ارز دیجیتال تا ۳۶ ساعت را ندارید.</p>
          </div>
        )}
        <div className='container inData'>
          <h4 className='notesTitle'>نکات مهم</h4>
          <div className='noteList'>
            {tab === tabTitles.toman ? (
              <>
                <span>
                  بعداز پرداخت از طریق درگاه الکترونیک شاپرک ، در صورت عدم شارژ کیف پول تومان ، لطفا صفحه خود را بعداز 2 دقیقه refresh کنید و مجدد بررسی کنید.
                </span>
                <span>
                  برای واریز ریال یکی از کارت های تایید شده را انتخاب کنید و اگر کارت تایید شده ندارید در قسمت کارت های بانکی در بخش حساب بانکی اضافه کنید.
                </span>
                <span>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</span>
                <span>
                  با توجه به ابلاغیه بانک مرکزی در مورد نظام کارمزد شاپرکی و همچنین کارمزد سرویس های پرداخت یاری، واریز ریالی از درگاه پرداخت به حساب کاربری در
                  اکسنوین مشمول کارمزد خواهد بود.
                </span>
              </>
            ) : tab === tabTitles.more ? (
              <>
                <span>برای استفاده از سرویس واریز شناسه دار سطح کاربری 2 به بالا نیاز است.</span>
                <span>
                  با انتخاب دکمه آبی رنگ " درخواست صدور شناسه واریز " سیستم به صورت خودکار ، شناسه واریز / شناسه پرداخت شما را ایجاد کرده و میتوانید بلافاصله از
                  این سرویس استفاده کنید.
                </span>
                <span>در ادامه شناسه واریز یا همان شناسه پرداخت برای شما ایجاد می شود و لیست حساب ها قابل مشاهده می باشد.</span>
                <span>
                  در آخر هنگام واریز وجه از روش های پایا، ساتنا و حساب به حساب حتما در بخش شناسه‌ی واریز، شناسه‌ی دریافتی خود را به دقت وارد کنید ، در غیر
                  اینصورت تراکنش شما عودت خواهد شد.
                </span>
                <span>توجه داشته باشید که پس از وصول مبلغ به حساب های اکسنوین ، کیف پول تومانی شما شارژ خواهد شد</span>
              </>
            ) : (
              <>
                <span>در این صفحه آدرس واریزی کیف پول (شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</span>
                <span>آدرس کیف پول و در صورت لزوم آدرس تگ/ممنو را از صفحه کپی کنید و هنگام انتقال ارز دیجیتال در کیف پول ( مبدا ) خود ، وارد کنید.</span>
                <span>سپس منتظر بمانید ، بعداز تایید (confirm) شبکه ، به صورت خودکار مقدار رمزارز انتقالی به کیف پول شما واریز خواهد شد.</span>
                <span>
                  در صورت هرگونه مشکل یا مغایرت ، Hash تراکنش یا همان Transaction ID به همراه اسکرین شات انتقال رمزارز ، از طریق تیکت به پشتیبانی ارسال کنید.
                </span>
              </>
            )}
          </div>
          <div className='spacer'></div>
        </div>
        <div className='container inData justStart '>
          <div className='topTilteBox'>
            <p className='t1'>تراکنش ها</p>
            <div className='refreshApi' onClick={init}>
              بروزرسانی
            </div>
          </div>
          <div className='historyList'>
            {loadingList ? <LoadingList /> :
              Array.from(listHistory).filter((e: any) => e.tab === (tab=="ارز دیجیتال"?"ارز دیجیتال":"تومان")).length > 0
                ? Array.from(listHistory).filter((e: any) => e.tab === (tab=="ارز دیجیتال"?"ارز دیجیتال":"تومان"))?.slice(0, sliceNum)?.map((i: any, n: any) =>
                  <div key={n} className='itemWithdraw'
                    onClick={() => dispatch(centerDialog(<Detail data={i} type={i.tab == tabTitles.toman ? "payment" : "deposit"} />))}
                  >
                    <section className='right'>
                      {i?.tab === tabTitles.crypto
                        ? <img className='icon' crossOrigin="anonymous" src={global.coins[i?.coin?.symbol]?.icon} alt="" />
                        : <img className='icon' crossOrigin='anonymous' src='./images/tmn.png' />}
                      <section className='txtBox'>
                        <p className='t1'>واریز {i.tab == tabTitles.toman ? tabTitles.toman : i?.coin?.symbol?.toUpperCase()}</p>
                        <p className='t2'>
                          {moment(i.createdAt)
                            .locale('fa')
                            .format('dddd D MMMM YYYY | HH:mm')}
                        </p>
                      </section>
                    </section>
                    <section className='left'>
                      <p className='t1'>{i.tab == tabTitles.toman ? tabTitles.toman : i?.coin?.symbol?.toUpperCase()} {autoFormatter(i?.amount)} </p>
                      <span className={`boxStatus bg${statusColor(i?.status)}`}>{statusTranslate(i?.status)}</span>
                    </section>
                  </div>)
                : <div className='noItem alignCenter'>موردی یافت نشد</div>}
          </div>
          {Array.from(listHistory).filter((e: any) => e.tab === tab).length > 3
            && <div className='btnShowMore' onClick={() => setSliceNum(sliceNum + 10)}>
              <span>مشاهده بیشتر</span>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Deposit;
