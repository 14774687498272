import { CircularProgress } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import Detail from 'components/Modules/Detail';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { socket } from 'services/socket';
import { statusColor, typeColor } from 'utils/color';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import { sideTranslate, statusTranslate } from 'utils/translate';
import { isUserLogin } from 'utils/user';

const historyTabTitles = {
  open: 'سفارش های باز',
  history: 'تاریخچه تبادلات',
};

const Orders = ({ reload }: { reload: number }) => {
  const global = useGlobal();
  const [orders, setOrders] = useState<null | []>(null);
  const dispatch = useDispatch<IDispatch>();
  const [historyTab, setHistoryTab] = useState(historyTabTitles.open);

  const ordersRender = (isOpen: boolean) => {
    const rows: JSX.Element[] = [];

    orders
      ?.filter((e: any) => (isOpen ? e.status === 'pending' || e.status === 'waiting' : e.status !== 'pending' && e.status !== 'waiting'))
      .forEach((e: any, i) => {
        rows.push(
          <div key={i} onClick={() => dispatch(centerDialog(<Detail data={e} type='order' />))}>
            <span className={`w70 color${typeColor(e.side)}`}>{sideTranslate(e.side)}</span>
            <span className='w90 inlineFlex'>
              <img crossOrigin='anonymous' src={global.coins[e.coin.symbol].icon} />
              <i>{e.coin.symbol.toUpperCase()}</i>
            </span>
            <span className='f1 ltr textRight'>
              {autoFormatter(e.total)} {e.pair.toUpperCase()}
            </span>
            <span className='disFlex'>
              <i className={`boxStatus bg${statusColor(e.status)}`}>{statusTranslate(e.status)}</i>
              {(e.status === 'pending' || e.status === 'waiting') && (
                <section
                  className='cancelOrder'
                  onClick={(o) => {
                    o.stopPropagation();
                    cancelAction(e._id);
                  }}
                ></section>
              )}
            </span>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder'>
        <div>
          <span className='w70'>نوع</span>
          <span className='w90'>ارز</span>
          <span className='f1'>سفارش</span>
          <span>وضعیت</span>
        </div>
        {rows}
      </div>
    );
  };

  const cancelAction = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order/${id}`, true).delete();

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت حذف شد'));
      init();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const init = async () => {
    const result = await gather(`${config.data.api}/v1/order`, true).get();
    if (result.code === 200) {
      setOrders(result.data);
    } else {
      setOrders([]);
    }
  };

  useEffect(() => {
    if (isUserLogin()) {
      init();
    } else {
      setOrders([]);
    }
  }, [reload]);

  useEffect(() => {
    socket.on('order', init);
    return () => {
      socket.removeListener('order', init);
    };
  }, []);

  return (
    <div className='whiteBg buyExpress'>
      <div className='container'>
        <div className='upTabs'>
          <div className='tabs'>
            <Tabs titles={historyTabTitles} selected={historyTab} setSelected={setHistoryTab} />
          </div>
          <div
            className='refresh'
            onClick={() => {
              setOrders(null);
              init();
            }}
          >
            بروزرسانی
          </div>
        </div>
        <div className='items'>
          {orders ? (
            historyTab === historyTabTitles.open ? (
              ordersRender(true)
            ) : (
              ordersRender(false)
            )
          ) : (
            <div className='loadingItem'>
              <CircularProgress size={22} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
