import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Orders from 'components/Layouts/Orders';
import Pairs from 'components/Layouts/Pairs';
import PreLogin from 'components/Layouts/PreLogin';
import VerifyList from 'components/Layouts/VerifyList';
import config from 'config';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { invert } from 'lodash';
import { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter, toFixed } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const tradeTabTitles = {
  otc: 'تبدیل فوری',
  // limit: 'معامله با قیمت معین',
};

const sideTabTitles = {
  buy: 'خرید',
  sell: 'فروش',
};

const Home = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const global = useGlobal();
  const ticker = useTicker();
  const balance = useBalance();
  const user = useUser();
  const [coin, setCoin] = useState(state?.default || Object.values(global.coins)[0]);
  const [focus, setFocus] = useState('payer');
  const [payer, setPayer] = useState(0);
  const [payee, setPayee] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [tradeTab, setTradeTab] = useState(tradeTabTitles.otc);
  const [sideTab, setSideTab] = useState(sideTabTitles.buy);
  const [reload, setReload] = useState(0);
  const [range, setRange] = useState({ x: 0 });

  const side = sideTab === sideTabTitles.buy ? 'buy' : 'sell';
  const userAsset = balance[sideTab === sideTabTitles.buy ? 'tmn' : coin.symbol];
  const trade = invert(tradeTabTitles)[tradeTab] as any;
  const currentPrice = ticker[side](coin.symbol + 'tmn');

  const resetInput = () => {
    setPayer(0);
    setPayee(0);
    setRange({ x: 0 });
  };

  const coinListRender = () => {
    const rows: JSX.Element[] = [];

    const data = Object.values(global.coins)
      .filter((e) => !config.digital.includes(e.symbol))
      .filter((e) => (e.pair.tmn as any)[trade])
      .splice(0, 3);

    if (!data.find((e) => e.symbol === coin.symbol)) {
      data[0] = coin;
    }

    data.forEach((e, i) => {
      rows.push(
        <div key={i} className={coin.symbol === e.symbol ? 'active' : ''} onClick={() => setCoin(e)}>
          <img crossOrigin='anonymous' src={e.icon} />
          <h2>
            {e.name} <span>({e.symbol.toUpperCase()})</span>
          </h2>
          <h3>{e.nameFa}</h3>
        </div>,
      );
    });

    return rows;
  };

  const buyOrSellAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order`, true).post({
      coin: coin._id,
      price: currentPrice,
      pair: 'tmn',
      side: side,
      trade: 'otc',
      amount: sideTab === sideTabTitles.buy ? payee : payer,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
      setReload(reload + 1);
      resetInput();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    if (focus === 'payer') {
      if (sideTab === sideTabTitles.buy) {
        setPayee(toFixed(payer / currentPrice, coin.decimal));
      } else {
        setPayee(toFixed(payer * currentPrice, coin.pair.tmn.precision));
      }
    }
  }, [payer, customPrice]);

  useEffect(() => {
    if (focus === 'payee') {
      if (sideTab === sideTabTitles.sell) {
        setPayer(toFixed(payee / currentPrice, coin.decimal));
      } else {
        setPayer(toFixed(payee * currentPrice, coin.pair.tmn.precision));
      }
    }
  }, [payee, customPrice]);

  useEffect(() => {
    console.log(1);
  }, [payer]);

  useEffect(() => {
    setFocus('payer');
    setPayer(toFixed((userAsset.balance * range.x) / 100, sideTab === sideTabTitles.buy ? coin.pair.tmn.precision : coin.pair.usdt.precision));
  }, [range]);

  useEffect(() => {
    resetInput();
  }, [sideTab, tradeTab, coin]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title={'مبادله فوری'} />
      <VerifyList />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='tabs'>
            <Tabs titles={tradeTabTitles} selected={tradeTab} setSelected={setTradeTab} />
          </div>
          <div className='step'>
            <span></span>
            <h3>
              مرحله اول <i>نوع ارز</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>ارز موردنظر را انتخاب کنید</div>
          <div className='coinList'>{coinListRender()}</div>
          <div className='otherCoin' onClick={() => dispatch(fullDrawer(<Pairs pair={['tmn']} trade={trade} setCoin={setCoin} />))}>
            سایر ارزها <span className='rightArrow'></span>
          </div>
          <div className='step step2'>
            <span></span>
            <h3>
              مرحله دوم <i>نوع معامله</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>نوع معامله را مشخص کنید</div>
          <div className='sellBuy'>
            <Tabs titles={sideTabTitles} selected={sideTab} setSelected={setSideTab} />
          </div>
          <div className='formFast'>
            {/* {tradeTab === tradeTabTitles.limit && (
              <div className='payed'>
                <img crossOrigin='anonymous' src='./images/price.png' />
                <NumericFormat
                  inputMode='numeric'
                  className='ltr textRight'
                  value={customPrice > 0 ? customPrice : ''}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => setCustomPrice(Number(e.value))}
                  placeholder='قیمت مورد نظر خود را اینجا وارد نمایید'
                />
              </div>
            )} */}
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.buy ? './images/tmn.png' : coin.icon} />
              <NumericFormat
                inputMode='numeric'
                className='ltr textRight'
                value={payer > 0 ? payer : ''}
                onFocus={() => setFocus('payer')}
                allowNegative={false}
                decimalScale={sideTab === sideTabTitles.buy ? 0 : coin?.decimal} 
                thousandSeparator={true}
                onValueChange={(e) => setPayer(Number(e.value))}
                placeholder='پرداخت می‌کنم (اینجا وارد نمایید)'
              />
            </div>
            <div className='convert' onClick={() => (sideTab === sideTabTitles.buy ? setSideTab(sideTabTitles.sell) : setSideTab(sideTabTitles.buy))}></div>
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.sell ? './images/tmn.png' : coin.icon} />
              <NumericFormat
                inputMode='numeric'
                className='ltr textRight'
                value={payee > 0 ? payee : ''}
                onFocus={() => setFocus('payee')}
                allowNegative={false}
                decimalScale={sideTab === sideTabTitles.sell ? 0 : coin?.decimal} 
                thousandSeparator={true}
                onValueChange={(e) => setPayee(Number(e.value))}
                placeholder='دریافت می‌کنم (اینجا وارد نمایید)'
              />
            </div>
            <div className='yourBalance' onClick={() => setPayer(userAsset.balance)}>
              <div>
                موجودی:{' '}
                <i>
                  {autoFormatter(isUserLogin() ? userAsset.balance : 0)} {sideTab === sideTabTitles.buy ? 'TMN' : coin.symbol.toUpperCase()}
                </i>
              </div>
              <div>
                قیمت: <i>{autoFormatter(currentPrice)} TMN</i>
              </div>
            </div>
            <div className='runRange'>
              <Slider
                x={range.x}
                xreverse={true}
                styles={{
                  track: {
                    backgroundColor: '#DFDFDF',
                  },
                  active: {
                    backgroundColor: '#2424DA',
                  },
                  thumb: {
                    backgroundColor: '#2424DA',
                    boxShadow: 'none',
                  },
                }}
                onChange={setRange}
              />
              <span style={{ width: range.x + '%' }}></span>
              <section>
                <i>0%</i>
                <i>25%</i>
                <i>50%</i>
                <i>75%</i>
                <i>100%</i>
              </section>
            </div>
            {/* {tradeTab === tradeTabTitles.limit && (
              <div className='receive receiveTop'>
                <div>
                  کارمزد
                  <i>{fee} درصد</i>
                </div>

                <div>
                  دریافتی شما
                  <i>
                    {autoFormatter((payee || 0) * (1 - fee / 100))} {sideTab === sideTabTitles.sell ? 'TMN' : coin.symbol.toUpperCase()}
                  </i>
                </div>
              </div>
            )} */}
            <div className='agreePay' onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : buyOrSellAction())}>
              با تایید {sideTab}، قوانین و مقررات را می پذیرم
              <i>تایید {sideTab}</i>
            </div>
          </div>
        </div>
      </div>
      <Orders reload={reload} />
      <Bottom />
    </>
  );
};

export default Home;
