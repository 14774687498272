import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingList = () => {
  return (
    <div className='loadingList'>
      <CircularProgress />
    </div>
  );
}

export default LoadingList;
