// import VerificationInput from "react-verification-input";
import { useDispatch } from "react-redux";
// import Countdown from "react-countdown";
import { useEffect, useState } from "react";
import _ from "lodash";
import gather from "utils/gather";
import config from "config";
import { errorAlert, successAlert } from "services/reducer/alert";
import { hideDialog } from "services/reducer/dialog";
import { hideLoading, showLoading } from "services/reducer/loading";
import Top from "../Top";
import PatchCode from "components/Helper/PatchCode";
import useTimer from "hooks/useTimer";
import { fullDrawer, hideDrawer } from "services/reducer/drawer";
import { getProfile } from "services/reducer/user";
import AddEmail from "./AddEmail";


const VerifyCodeEmailPhone = ({ flag, value }: { flag: boolean, value: any }) => {
  const [code, setCode] = useState<number[]>([]);
  const dispatch = useDispatch<any>();
  const timer = useTimer();

  const send = async () => {
    dispatch(showLoading());

    const result = flag
      ? await gather(`${config.data.api}/v1/user/mobile/verify`, true).post({ code:code.join('') })
      : await gather(`${config.data.api}/v1/user/email/verify`, true).post({ code:code.join('') })

    if (result.code === 200) {
      dispatch(successAlert("عملیات با موفقیت انجام شد"));
      dispatch(hideDrawer());
      dispatch(hideDialog());
      await dispatch(getProfile());
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  };

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  const reloadCode = async () => {
    dispatch(showLoading());

    const result = flag
      ? await gather(`${config.data.api}/v1/user/mobile`, true).post({ "mobile": value })
      : await gather(`${config.data.api}/v1/user/email`, true).post({ "email": value })

    if (result.message === 200) {
      timer.start();
      setCode([]);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  useEffect(() => {
    timer.start();
  }, []);

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      send();
    }
  }, [code]);

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={`کد تاییدارسالی به ${flag ? "تلفن همراه" : "ایمیل"}`} />
        <div className='logo'></div>
        <div className='container inData verifyCodeEmailPhone'>
          <h4 className='confirmTitle'>کد تایید به {flag ? "تلفن همراه" : "ایمیل"} {value} ارسال شد</h4>
          <PatchCode code={code} setCode={setCode} />
          <div className='changeMobile'>
            <i>
              {!timer.isFinish ? (
                <>
                  {timer.minute ? `${timer.minute} دقیقه و ` : ''}
                  {timer.second} ثانیه
                </>
              ) : (
                <div className='active' onClick={reloadCode}>
                  ارسال مجدد کد
                </div>
              )}
            </i>
            <p onClick={() => openMenu(<AddEmail />)}>ویرایش ایمیل</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyCodeEmailPhone