import config from 'config';
import { IBuySell } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { socket } from 'services/socket';
import gather from 'utils/gather';
import { autoFormatter, formatter, toFixed } from 'utils/numeral';

let oldPrice = 0;

const OrderBook = ({ orderList, setOrderList, coinPrice, precision, setCustomPrice, coin, pair }: any) => {
  const [up, setUp] = useState(true);

  const list = (data: IBuySell[], type: string) => {
    const rows: React.ReactElement[] = [];

    const limit = 7;
    // const limit = 20;

    const newData = data.slice(0, limit);

    const max = Math.max(...newData.map((e) => e.quantity));
    const min = Math.min(...newData.map((e) => e.quantity));

    if (type === 'sell') {
      newData.reverse();
    }

    newData.forEach((value, i) => {
      let percent = ((value.quantity - min) / (max - min)) * 100;
      if (percent < 1) {
        percent = 1;
      }

      rows.push(
        <span key={i} onClick={() => setCustomPrice(toFixed(value.price, precision))}>
          <i>{autoFormatter(value.price)}</i>
          <p>{autoFormatter(value.amount)}</p>
          <section style={{ width: percent + '%' }}></section>
        </span>,
      );
    });

    for (let i = rows.length; i < limit; i++) {
      rows[type === 'sell' ? 'unshift' : 'push'](
        <span key={1000 + i} className='noData'>
          <i>0.0</i>
          <p>0.0</p>
          <section></section>
        </span>,
      );
    }

    return rows;
  };

  const orderBook = (result: any) => {
    setOrderList(result);
  };

  const getData = async () => {
    const result = await gather(`${config.data.api}/v1/orderBook/${coin.symbol}/${pair}`).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }
  };

  useEffect(() => {
    setOrderList({ buy: [], sell: [] });
    getData();
  }, [coin]);

  useEffect(() => {
    if (coinPrice > oldPrice) {
      setUp(true);
    } else {
      setUp(false);
    }

    oldPrice = coinPrice;
  }, [coinPrice]);

  useEffect(() => {
    socket.emit('orderBook', coin.symbol + pair);
    socket.on('orderBook', orderBook);

    return () => {
      socket.emit('unOrderBook', coin.symbol + pair);
      socket.removeListener('orderBook', orderBook);
    };
  }, [coin]);

  return (
    <div className='leftMarket'>
      <div className='depth depthTitle'>
        <span>
          <i>قیمت</i>
          <p>مقدار</p>
        </span>
      </div>
      <div className='depth'>{list(orderList.sell, 'sell')}</div>
      <h5 className={`${up ? 'colorGreen' : 'colorRed'}`} onClick={() => setCustomPrice(toFixed(coinPrice, precision))}>
        {formatter(coinPrice, precision)}
      </h5>
      <div className='depth'>{list(orderList.buy, 'buy')}</div>
    </div>
  );
};

export default OrderBook;
