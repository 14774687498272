import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDQMkz6LwSAv9FIHtnThyxdJ4VVInSUqvY",
  authDomain: "notiftestexnovin.firebaseapp.com",
  projectId: "notiftestexnovin",
  storageBucket: "notiftestexnovin.firebasestorage.app",
  messagingSenderId: "954572354471",
  appId: "1:954572354471:web:0e6aa02dea0081f264bc82",
  measurementId: "G-EFNHQQ5JNT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics, 'notification_received');


export const messaging = getMessaging(app);

export const generatatToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    getToken(messaging, { vapidKey: 'BIVnf6CwslLtR-ePk6-nc4F8w6_qmFm9hU4I2ga_DKs8bZmGLsvqsUb1_QGSDuA0XgmfYv9py8IETWKp_GevzW0' }).then((currentToken) => {
      if (currentToken) {
        console.log(" token : ",currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  } else {
    alert("You denied for the notification");
  }
}