import 'assets/css/fonts.css';
import 'assets/css/main.css';
import 'assets/css/newStyle.scss';
import App from 'components/App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'services/store';
import reportWebVitals from 'services/worker/reportWebVitals';
import * as serviceWorkerRegistration from 'services/worker/serviceWorkerRegistration';
import version from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

console.log(`version: ${version.version}`);

serviceWorkerRegistration.register();
reportWebVitals();
