import React, { useState } from 'react';
import { CircularProgress, DialogContent, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import gather from 'utils/gather';
import config from 'config';
import { centerDialog, hideDialog } from 'services/reducer/dialog';
// import ConfirmCodeEmailPhone from './ConfirmPhoneCode';
import { errorAlert } from 'services/reducer/alert';
import { numbersToEn } from 'utils/tools';
import Top from '../Top';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import VerifyCodeEmailPhone from './VerifyCodeEmailPhone';


const EditPhone = () => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  // const [errorMobile, setErrorMobile] = useState(0);
  const [myLoading, setMyLoading] = useState(false);


  // const handleMobile = (e: any) => {
  //   setMobile(e.target.value)
  //   let newMobile = e.target.value.startsWith("0") ? e.target.value : '0' + e.target.value;
  //   if (newMobile.length === 11) {
  //     setErrorMobile(2);
  //   } else {
  //     setErrorMobile(0)
  //   }
  // }

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  const send = async () => {
    let newMobile = mobile.startsWith("0") ? mobile : '0' + mobile;
    if (newMobile.length !== 11) {
      dispatch(errorAlert('لطفا شماره همراه خود را بررسی کنید'));
      // setErrorMobile(1);
      // setTimeout(() => { setErrorMobile(0) }, 3000);
      return
    }
    // setErrorMobile(2);

    setMyLoading(true)

    const result = await gather(`${config.data.api}/v1/user/mobile`, true).post({ "mobile": numbersToEn(mobile?.toString()) })

    if (result.code === 200) {
      openMenu(<VerifyCodeEmailPhone flag={true} value={numbersToEn(mobile?.toString())} />);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    setMyLoading(false)
  }

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={'تلفن همراه جدید'} />
        <div className='logo'></div>
        <div className='container inData addEmail'>
          <div className='mainInput'>
            <p>شماره همراه جدید</p>
            <div>
              <span className=''></span>
              <input
                type="tel"
                autoComplete='one-time-code'
                placeholder='+98'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div>
          <div className="textInfoDialog mt_25">
            <i className="iconInfo"></i>
            <h6 className='t1'>با توجه به احراز هویت شما، نیاز است که شماره موبایل جدید حتما به نام صاحب حساب کاربری باشد.</h6>
          </div>
          {myLoading
            ? <div className="loadingCircular mt_15"><CircularProgress size={24} style={{ color: "#fff" }} /></div>
            : <div className='largeButton' onClick={send}>ادامه</div>}
        </div>
      </div>
    </div>
  )
}

export default EditPhone