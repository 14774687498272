import SelectBox from 'components/Helper/SelectBox';
import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { ICoin } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';

const tabTitles = {
  new: 'ثبت هشدار جدید',
  list: 'لیست هشدارها',
};

const pairTab: any = {
  tmn: 'تومان',
  usdt: 'تتر',
};

const PriceAlert = () => {
  const [tab, setTab] = useState(tabTitles.new);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const global = useGlobal();
  const ticker = useTicker();
  const [coin, setCoin] = useState<ICoin | null>(null);
  const [pair, setPair] = useState<string>('');
  const [newPrice, setNewPrice] = useState(0);

  const pairFind = Object.keys(pairTab).find((key) => pairTab[key] === pair)!;
  const price = ticker.price(coin?.symbol + pairFind, 0);

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/alert`, true).post({
      coin: coin?._id,
      price: newPrice,
      pair: pairFind,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('ثبت با موفقیت انجام شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const del = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/alert/${id}`, true).delete();

    if (result.code === 200) {
      await init();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list.forEach((e: any, i) => {
      rows.push(
        <div>
          <span>
            <i onClick={() => del(e._id)}></i>
          </span>
          <h3>{(e.coin.symbol + e.pair).toUpperCase()}</h3>
          <h4>
            قیمت فعلی <b>{autoFormatter(ticker.price(e.coin.symbol + e.pair, 0))}</b>
          </h4>
          <h4>
            قیمت هدف <b>{autoFormatter(e.price)}</b>
          </h4>
          <h4>
            وضعیت <b>{e.isActive ? 'فعال' : 'غیرفعال'}</b>
          </h4>
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/alert`, true).get();
    if (result.code === 200) {
      setList(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'هشدار قیمت'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.new ? (
            <>
              <div className='step stepDeposit'>
                <i>۱</i>
                <h3>انتخاب بازار</h3>
              </div>
              <SelectBox
                data={pairTab}
                search={false}
                value={pair ? <h3>{pair}</h3> : <>نوع بازار را انتخاب کنید</>}
                template='default'
                setSelected={setPair}
              />
              <div className='step stepDeposit'>
                <i>۲</i>
                <h3>انتخاب ارز</h3>
              </div>
              <SelectBox
                data={global.coins}
                search={true}
                value={
                  coin ? (
                    <>
                      <img crossOrigin='anonymous' src={coin.icon} />
                      <h3>
                        {coin.name} ({coin.symbol.toUpperCase()})
                      </h3>
                    </>
                  ) : (
                    <>ارز خود را انتخاب کنید</>
                  )
                }
                template='coin'
                setSelected={setCoin}
              />
              <div className='listCashCode'>
                <section>
                  <div>
                    <span>قیمت فعلی</span>
                    <span>{autoFormatter(price)} تومان</span>
                  </div>
                </section>
              </div>
              <div className='step stepDeposit'>
                <i>۳</i>
                <h3>قیمت هدف</h3>
              </div>
              <div className='mainInput'>
                <div>
                  <NumericFormat
                    inputMode='numeric'
                    className='ltr'
                    value={newPrice > 0 ? newPrice : ''}
                    allowNegative={false}
                    thousandSeparator={true}
                    onValueChange={(e) => setNewPrice(Number(e.value))}
                    placeholder='0.0'
                  />
                </div>
              </div>
              <div className='spacer'></div>
              <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
                ثبت و تایید
              </div>
            </>
          ) : (
            <div className='alertList'>{listRender()}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceAlert;
